import React from 'react';
import './titlebar.css';

class TitleBar extends React.Component{
    render(){
        return (
            <div className="TitleBar">
                
            </div>
        )
    }
}

export default TitleBar;